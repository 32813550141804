import { Component,OnInit } from '@angular/core';
import { DataService } from './services/data.service';
import { Observable, Subscription } from 'rxjs';
import { Componente } from './interfaces/interfaces';
import { AuthService } from './services/auth.service';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';

import { ToastService } from './services/toast.service';
import { StorageService } from './services/storage.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  componentes: Observable<Componente[]>;
  usuario: any;
  private authSub: Subscription;
  constructor(private data: DataService,
    private authService: AuthService,
    private menuCtrl: MenuController,
    private toastService: ToastService,
    private storage: StorageService
    ) {;
    this.componentes = this.data.getMenu();

  }
  async ngOnInit(){
  this.authSub = this.authService.isAuthenticated.subscribe(isAuth => {
      if (isAuth) {
        this.menuCtrl.enable(true, 'first');
        this.usuario = this.storage.getStorage('emprendimiento').then((val) => {
          this.usuario = val;
      });
      } else {
        this.menuCtrl.enable(false, 'first');
      }
    });
  }

  logout(){
    this.authService.Logout();
    this.toastService.presentToast('Sesión cerrada con éxito','success');
  }

}
