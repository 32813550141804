import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Storage } from '@ionic/storage';
// Expire time in seconds
const TTL = 5;
const TTLTOKEN = 60 * 60;
// Key to identify only cached API data
const CACHE_KEY = '_mycached_';
@Injectable({
  providedIn: 'root'
})

export class StorageService {

  constructor(private http: HttpClient,private storage: Storage) {
    this.initStorage();
  }

  async initStorage(){
    await this.storage.create();
  }

/////////////PARA MANEJO DE TOKEN/////////////////////////

   async getToken(): Promise<any> {
    return this.storage.get('token');
  }
  async guardarToken(url: string, value: any){
    const validUntil = (new Date().getTime()) + TTLTOKEN * 1000;
      await this.storage.set(url,{validUntil,value});
    }
////////////////////////////////////////////////////////

//////////////////PARA EL RESTO DE SERVICIOS///////////

  async saveStorage(url: string, value: any){
  const validUntil = (new Date().getTime()) + TTL * 1000;
    await this.storage.set(url,{validUntil,value});
  }
async getTypeAccomodation(){
    return this.storage.get('emprendimiento').then(res => res.value.accommodation);
}
  async getStorage(url: string){
    const storedValue = await this.storage.get(url);
    return storedValue ? storedValue.value : null;
  }

  //chequea si caduco el ttl
  async isValidDate(url: string){
    const currentTime = new Date().getTime();
    const storedValue = await this.storage.get(url);
    if (!storedValue) {
      //console.log('no hay datos guardados');
      return Promise.resolve(false);
    }
    if(storedValue.validUntil < currentTime){
      //console.log('actualizo los datos, paso el tiempo de validacción');
      return Promise.resolve(false);
    }
     else {
      //console.log('hay datos guardados');
      return Promise.resolve(true);
    }
  }
////////////////////////////////////////////////////////

/////////////////CLEAR DATA////////////////////////////
async clearCachedData() {
  const keys = await this.storage.keys();

  keys.map(async key => {
      await this.storage.remove(key);

  });
}
async eliminarStorage(url: string){
  await this.storage.remove(url);
}
////////////////////////////////////////////////////////
  }
