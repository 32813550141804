import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';/*
import { Geolocation } from '@ionic-native/geolocation/ngx'; */
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe } from '@angular/common';
import { PipesModule } from './pipes/pipes.module';

import { JwtInterceptor } from './interceptors/jwt.interceptor';
import { DateRangePipe } from './pipes/date-range.pipe';
import { FiltroPipe } from './pipes/filtro.pipe';
import { PapaParseModule } from 'ngx-papaparse';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule,IonicModule.forRoot(), IonicStorageModule.forRoot(),
     AppRoutingModule,HttpClientModule,FormsModule,ReactiveFormsModule, NoopAnimationsModule,PipesModule,PapaParseModule, ServiceWorkerModule.register('ngsw-worker.js', {
  enabled: environment.production,
  // Register the ServiceWorker as soon as the app is stable
  // or after 30 seconds (whichever comes first).
  registrationStrategy: 'registerWhenStable:30000'
})
     ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    DatePipe,
    PipesModule,
    DateRangePipe,
    FiltroPipe,
  {provide : HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true}],
  bootstrap: [AppComponent],
})
export class AppModule {}
