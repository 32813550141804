import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(private toastController: ToastController) {}

  async presentToast(texto: string,color: string) {
    const toast = await this.toastController.create({
      message: texto,
      duration: 2000,
      color
        });
    toast.present();
  }
}
