import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FiltroPipe } from './filtro.pipe';
import { DateRangePipe } from './date-range.pipe';



@NgModule({
  declarations: [
    FiltroPipe,
    DateRangePipe
  ],
  exports: [FiltroPipe,DateRangePipe],
  imports: []
})
export class PipesModule{}
