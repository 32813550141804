import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { UsuarioGuard } from './guards/usuario.guard';
import { CheckLoginGuard } from './guards/check-login.guard';
import { ReservarInstalacionSinPageModule } from './pages/reserva-sin/reservar-instalacion-sin/reservar-instalacion-sin.module';
const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'inicio',
    loadChildren: () => import('./pages/inicio/inicio.module').then( m => m.InicioPageModule),
    canLoad: [UsuarioGuard]
  },

  {
    path: 'emprendimiento',
    loadChildren: () => import('./pages/emprendimiento/emprendimiento.module').then( m => m.EmprendimientoPageModule),
    canLoad: [UsuarioGuard]
  },
  {
    path: 'personal',
    loadChildren: () => import('./pages/personal/personal.module').then( m => m.PersonalPageModule),
    canLoad: [UsuarioGuard]
  },
  {
    path: 'perfil',
    loadChildren: () => import('./pages/perfil/perfil.module').then( m => m.PerfilPageModule),
    canLoad: [UsuarioGuard]
  },
  {
    path: 'servicio',
    loadChildren: () => import('./pages/servicio/servicio.module').then( m => m.ServicioPageModule),
    canLoad: [UsuarioGuard]
  },
  {
    path: 'caja',
    loadChildren: () => import('./pages/caja/caja.module').then( m => m.CajaPageModule),
    canLoad: [UsuarioGuard]
  },
  {
    path: 'cliente',
    loadChildren: () => import('./pages/cliente/cliente.module').then( m => m.ClientePageModule),
    canLoad: [UsuarioGuard]
  },
  {
    path: 'modal-servicio',
    loadChildren: () => import('./pages/servicio/modal/modal-servicio.module').then( m => m.ModalServicioPageModule),
    canActivate: [UsuarioGuard]
  },
  {
    path: 'modal-emprendimiento',
    loadChildren: () => import('./pages/emprendimiento/modal/modal-emprendimiento.module').then( m => m.ModalEmprendimientoPageModule),
    canActivate: [UsuarioGuard]
  },
  {
    path: 'modal-horarios',
    loadChildren: () => import('./pages/emprendimiento/modal-horarios/modal-horarios.module').then( m => m.ModalHorariosPageModule),
    canActivate: [UsuarioGuard]
  },
  {
    path: 'modal-personal',
    loadChildren: () => import('./pages/personal/modal/modal-personal.module').then( m => m.ModalPersonalPageModule),
    canActivate: [UsuarioGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule),
    canActivate: [CheckLoginGuard]
  },
  {
    path: 'instalaciones',
    loadChildren: () => import('./pages/instalaciones/instalaciones.module').then( m => m.InstalacionesPageModule),
    canLoad: [UsuarioGuard]
  },
  {
    path: 'modal-instalacion-edit',
    loadChildren: () => import('./pages/modal-instalacion-edit/modal-instalacion-edit.module').then( m => m.ModalInstalacionEditPageModule),
    canActivate: [UsuarioGuard]
  }
 ,
 {
  path:'modal-unidad',
  loadChildren: () => import('./pages/instalaciones/modal-unidad/modal-unidad.module').then( m => m.ModalUnidadPageModule),
  canActivate: [UsuarioGuard]
  },

  {
    path: 'cliente-modal',
    loadChildren: () => import('./pages/cliente/modal/cliente-modal.module').then( m => m.ClienteModalPageModule),
    canActivate: [UsuarioGuard]
  },
  {
    path: 'modal-caja',
    loadChildren: () => import('./pages/caja/modal/modal-caja.module').then( m => m.ModalCajaPageModule),
    canActivate: [UsuarioGuard]
  },
  {
    path: 'reservas',
    loadChildren: () => import('./pages/reservas/reservas.module').then( m => m.ReservasPageModule),
    canLoad: [UsuarioGuard]
  },
  {
    path: 'reservas-sin',
    loadChildren: () => import('./pages/reserva-sin/reserva-sin.module').then( m => m.ReservaSinPageModule),
    canLoad: [UsuarioGuard]
  },
  {
    path: 'modal-reservas',
    loadChildren: () => import('./pages/modal-reservas/modal-reservas.module').then( m => m.ModalReservasPageModule),
    canActivate: [UsuarioGuard]
  },
  {
    path: 'search-disponibilidad',
    loadChildren: () => import('./pages/reservas/search-disponibilidad/search-disponibilidad.module').then
    ( m => m.SearchDisponibilidadPageModule),
    canActivate: [UsuarioGuard]

  },
  {
    path: 'reservas-form',
    loadChildren: () => import('./pages/reservas/formulario/reservas-form.module').then( m => m.ReservasFormPageModule),
    canActivate: [UsuarioGuard]
  },
  {
    path: 'search-reservas',
    loadChildren: () => import('./pages/reservas/search-reservas/search-reservas.module').then( m => m.SearchReservasPageModule),
    canActivate: [UsuarioGuard]
  },
  {
    path: 'checkin',
    loadChildren: () => import('./pages/checkin/checkin.module').then( m => m.CheckinPageModule),
    canActivate: [UsuarioGuard]
  },
  {
    path: 'checkin-form',
    loadChildren: () => import('./pages/checkin-form/checkin-form.module').then( m => m.CheckinFormPageModule),
    canActivate: [UsuarioGuard]
  },
  {
    path: 'checkout',
    loadChildren: () => import('./pages/checkout/checkout.module').then( m => m.CheckoutPageModule),
    canActivate: [UsuarioGuard]
  },
  {
    path: 'reservas-instalacion',
    loadChildren: () => import('./pages/reservas-instalacion/reservas-instalacion.module').then( m => m.ReservasInstalacionPageModule),
    canActivate: [UsuarioGuard]
  },
  {
    path: 'reservas-ver',
    loadChildren: () => import('./pages/reservas/reservas-lista/reservas-ver.module').then( m => m.ReservasVerPageModule),
    canActivate: [UsuarioGuard]
  },
  {
    path: 'modal-reservas-ver',
    loadChildren: () => import('./pages/modal-reservas-ver/modal-reservas-ver.module').then( m => m.ModalReservasVerPageModule),
    canActivate: [UsuarioGuard]
  },
  {
    path: 'reserva-sin',
    loadChildren: () => import('./pages/reserva-sin/reserva-sin.module').then( m => m.ReservaSinPageModule)
  },
  {
    path: 'reservar-instalacion-sin',
    loadChildren: () => import('./pages/reserva-sin/reservar-instalacion-sin/reservar-instalacion-sin.module')
    .then( m => m.ReservarInstalacionSinPageModule)
  },
  {
    path: 'search-reservas-sin',
    loadChildren: () => import('./pages/reserva-sin/search-reservas-sin/search-reservas-sin.module')
    .then( m => m.SearchReservasSinPageModule)
  },
  {
    path: 'checkin-sin',
    loadChildren: () => import('./pages/reserva-sin/checkin-sin/checkin-sin.module')
    .then( m => m.CheckinSinPageModule)
  }
  ,
  {
    path: 'search-disponibilidad-sin',
    loadChildren: () => import('./pages/reserva-sin/search-disponibilidad-sin/search-disponibilidad-sin.module')
    .then( m => m.SearchDisponibilidadSinPageModule)
  },
  {
    path: 'seleccion-horario',
    loadChildren: () => import('./pages/reserva-sin/seleccion-horario/seleccion-horario.module').then( m => m.SeleccionHorarioPageModule)
  }
  ,
  {
    path: 'formulario-sin',
    loadChildren: () => import('./pages/reserva-sin/reservar-instalacion-sin/formulario/formulario.module')
    .then( m => m.FormularioPageModule)
  },
  {
    path: 'checkout-form',
    loadChildren: () => import('./pages/checkout-form/checkout-form.module').then( m => m.CheckoutFormPageModule)
  },
  {
  path: 'modal-ver-instalacion',
  loadChildren: () => import('./pages/instalaciones/modal-ver-instalacion/modal-ver-instalacion.module')
  .then( m => m.ModalVerInstalacionPageModule)
},
  {
    path: 'modal-reservas-servicios',
    loadChildren: () => import('./pages/modal-reservas-servicios/modal-reservas-servicios.module').then( m => m.ModalReservasServiciosPageModule)
  },
  {
    path: 'modal-reservas-extras',
    loadChildren: () => import('./pages/modal-reservas-extras/modal-reservas-extras.module').then( m => m.ModalReservasExtrasPageModule)
  },
  {
  path:'modal-disponibilidad',
  loadChildren: () => import('./pages/inicio/modal-ocupacion/modal-disponibilidad.module').then( m => m.ModalDisponibilidadPageModule),
}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
