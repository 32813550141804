import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtro'
})
export class FiltroPipe implements PipeTransform {

  transform(arreglo: any[], texto: string): any {
    if(texto === ''){
        return arreglo;
    }
    const filtrado = arreglo.filter(item => item.name.toLowerCase().includes(texto.toLowerCase()));
    console.log(filtrado);
    return filtrado;

}
}
