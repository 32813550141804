/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable quote-props */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Storage } from '@ionic/storage';
import { tap, catchError, mergeMap, filter } from 'rxjs/operators';
import { Emprendimiento } from '../models/Emprendimiento';
import { environment } from '../../environments/environment';
import { StorageService } from './storage.service';
import { ToastService } from './toast.service';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class EmprendimientoService {
  emprendimiento: any;
  idtest= 0;
  empreSubjec= new BehaviorSubject(null);
  id=  new BehaviorSubject(0);
  token: string;
  emp = new Emprendimiento();
  misionesData: any;
  constructor(private http: HttpClient,private storage: Storage,
    private storageService: StorageService,
    private toastService: ToastService) {
    this.getID();
    }


  async agregarEmprendimiento(emprendimiento: Emprendimiento){
  }
  async updateEmprendimiento(emprendimiento: Emprendimiento){
    const id = await this.getEmprendimientoID();
    this.id=id;
    emprendimiento.accommodation = 'yes';
        return new Promise(resolve => {
          this.http.put(`${environment.hostURL}/emprendimientos/${id}`,emprendimiento).
          subscribe((resp: any)=>{
            if (resp.msg){
              this.emprendimiento = resp.data;
              this.storageService.saveStorage('emprendimiento',resp.data);
              this.toastService.presentToast('Emprendimiento actualizado correctamente','success');
              resolve(true);
            }
            else {
              this.toastService.presentToast(resp.error,'danger');
            resolve(false);}
          },
          (err) => {this.toastService.presentToast('Error al actualizar el emprendimiento','danger');});
        });

  }

  async getEmprendimientoID(){
    if(await this.storage.get('emprendimiento') !== null){
      const id = await this.storage.get('emprendimiento').then(val => val.value.id);
      const a = await this.storage.get('emprendimiento').then(val => val.value);
      this.idtest = id;
      return id;
  }else {
    return null;
  }
  }
  async getID(){
    if(await this.storage.get('emprendimiento') !== null){
      const id = await this.storage.get('emprendimiento').then(val => val.value.id);
      this.idtest = id;
      return id;
  }else {
    return null;
  }
  }

  async getEmprendimiento() {
    const id = await this.getEmprendimientoID();
    const no_expiro = await this.storageService.isValidDate('emprendimiento');

    if (no_expiro) {
      return this.storageService.getStorage('emprendimiento');
    }

    return new Promise((resolve, reject) => {
      this.http.get(`${environment.hostURL}/emprendimientos/${id}`)
        .subscribe(
          (resp: any) => {
            if (resp.ok) {
              this.emprendimiento = resp.data;
              this.storageService.saveStorage('emprendimiento', this.emprendimiento[0]);
              resolve(this.emprendimiento[0]);
            } else {
              this.toastService.presentToast(resp.error, 'danger');
              reject(resp.error);
            }
          },
          (err) => {
            this.toastService.presentToast('Error al obtener el emprendimiento', 'danger');
            reject(err);
          }
        );
    });
  }
  async getLocalidadesDepartamentos(){
    return new Promise((resolve, reject) => {
      this.http.get(`${environment.hostURL}/datos-localizacion`).subscribe(
        (res: any) => {
          if (res.success) {
            this.misionesData = res.data
            .find(pais => pais.nombre === 'ARGENTINA').provincias
            .find(provincia => provincia.nombre === 'MISIONES');
             if(this.misionesData){
              resolve(true);
             }else {
              resolve(false);
             }
          } else {
            reject(false);
          }
        },
        (err) => {
          reject(false);
        }
      );
   });
  }
  getLocalidadesDepartamentosPaisValue(){
    return this.misionesData;
  }
   getData(){
    return {...this.emprendimiento};
  }

  getEmpre(){
    return this.empreSubjec.asObservable();
  }
  setEmpre(value: any){
    this.empreSubjec.next(value);
  }
}


