import { Injectable } from '@angular/core';
import {CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { filter,map,take } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class CheckLoginGuard implements CanActivate {
  constructor(private authService: AuthService,
    private router: Router) {}
  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return   this.authService.isAuth.pipe(
      filter(val => val !== null),
      take(1),
      map(isAuth =>{
        //console.log(isAuth);
        if(!isAuth){
        return true;
        }
        this.router.navigateByUrl('/inicio');
        return false;
      })
    );
  }
}
