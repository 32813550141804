// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  /* hostURL:'https://turismorural-test.misiones.gob.ar/api', */
  //hostURL:'https://app.misiones.tur.ar/api-turismo/public/api',
  mapboxToken : 'pk.eyJ1IjoiZGFuaWVsc2FuciIsImEiOiJja2ZvcW50bmYwMm84MnhvN3dnemgyMDd2In0.dXdsjrsitzbVr3lrYNN2pA',
  urlUploadArchivos: 'https://app.misiones.tur.ar/archivos/upload.php',
  urlArchivos: 'https://app.misiones.tur.ar/archivos/',
  hostURL:'https://app.misiones.tur.ar/api-turismo/public/api'

/*   http://192.168.117.90:8000 */
 /*  http://127.0.0.1:8000/api/flujo_dinero */
};


/* en la app tiene que ir http
https://blooming-headland-40853.herokuapp.com/api
http://127.0.0.1:8000/api
http://
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`,  s`zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
