/* eslint-disable quote-props */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/semi */
/* eslint-disable @typescript-eslint/member-delimiter-style */
/* eslint-disable @typescript-eslint/dot-notation */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { switchMap, tap } from 'rxjs/operators';
import { Storage } from '@ionic/storage';
import { StorageService } from './storage.service';
import { EmprendimientoService } from './emprendimientos.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
   _storage: Storage | null = null;
  isAuth: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  token: string = null;
  temp: any;
  datosUser: any = {
    email: '',
    password : '',
    username : '',
    image_path: ''
  };

  constructor(
    private http: HttpClient,public storage: Storage, public emprendimientoService: EmprendimientoService,
    private storageService: StorageService,private router: Router) {
    this.loadToken();
    }

/*   async initStorage(){
    const storage = await this.storage.create();
  } */

    get isAuthenticated() {
      return this.isAuth.asObservable();
    }

    async loadToken(){
      const token = await this.storage.get('token');
      if(token){
        this.token = token.value;
        this.isAuth.next(true);
      }
      else {
        this.token = null;
        this.isAuth.next(false);
      }
    }

     Login(credentials: {email, password}): Observable<any> {
      return this.http.post(`${environment.hostURL}/login`, credentials).pipe(
        switchMap((res: { token, data}) =>{
          this.datosUser.email = res.data.email;
          this.datosUser.username = res.data.username;
          this.datosUser.image_path = res.data.image_path? res.data.image_path : '../../../assets/av-1.png';
          this.datosUser.password =  credentials.password;
          localStorage.setItem('userData', JSON.stringify(this.datosUser));
          this.temp =res.data;
          const usern = {
              id : res.data.id,
              username : res.data.username,
              email : res.data.email,
              image_path : res.data.image_path,
          }
          this.token = res.token;
          const token = this.storageService.guardarToken('token',res.token);
          const user = this.storageService.guardarToken('usuario',usern);
          const emprendimiento= this.storageService.guardarToken('emprendimiento', res.data.entrepreneurship);
/*        this.emprendimientoService.empreSubjec.next(emprendimiento_id);
          this.emprendimientoService.setEmpre(emprendimiento_id); */
         return from(Promise.resolve(res.data.entrepreneurship_id)); //
        }),
        tap(_ => {
          this.isAuth.next(true);
        })
      )
    }

   Logout(){
     this.http.post(`${environment.hostURL}/logout`,{}).pipe(
      switchMap(_ => {
        this.token = null;
        this.storage.clear();
        localStorage.setItem('userData', null);
        return of(null)

      }),
      tap(_ =>{
        this.isAuth.next(false);
        this.router.navigateByUrl('/',{replaceUrl: true})
      })
     ).subscribe()
}

clearData(){
     this.storage.clear();
     this.router.navigateByUrl('/',{replaceUrl: true})
}
}/*



Logout(){
  return new Promise(resolve=>{
   this.http.post(`${environment.hostURL}/logout`,{}).subscribe(
    (res: any) =>{
      if(typeof(res)!==undefined){
        this.token = null;
        this.storage.clear();
        this.isAuth.next(false);
        resolve(true);
          }
      else {
        resolve(false);
      }
        },
        err => console.log(err.status));
  });

} */

