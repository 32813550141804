import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Componente } from '../interfaces/interfaces';
import { Storage } from '@ionic/storage-angular';
import { BehaviorSubject, from, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private data = [];
  private dataTable = [] ;
  private homeData = new BehaviorSubject<any[]>([]);
  constructor(private http: HttpClient,private storage: Storage) {}


  getTableValue(){
    return this.homeData.asObservable();
  }

  getMenu(){
       return this.http.get<Componente[]>('assets/data/opt.json');
  }
  getMenu3(){
    return this.http.get<Componente[]>('assets/data/opt2.json');
}
getTable(){
  return new Promise(resolve =>{
    this.http.get<any>('assets/data/table.json').subscribe((res: any)=>{
    if(typeof(res) !==undefined){
      this.dataTable= res;
      this.homeData.next([...this.dataTable]);
      resolve(true);
      }
    else {
      resolve(false);
      }
    },
    err => resolve(false));
  });




  return ;
}

  async getMenu2(){
    const accommodation = await this.storage.get('emprendimiento').then(emp=> emp.accommodation);
    return new Promise(resolve =>{
      this.http.get('assets/data/opt.json').subscribe((res: any)=>{
        if(typeof(res) !==undefined){
          const acc = accommodation;
          const i = res.findIndex( index => index.redirectTo ===( acc==='no'?'/reservas':'/reservas-sin'));
          res.splice(i,1);
          this.data = res;
          resolve(true);
        }
        else {
          resolve(false);
        }
          },
          err => err);
    });

}
/*  async getMenu2(){
    const accommodation = await this.storage.get('emprendimiento').then(emp=> emp.accommodation);
    return new Promise(resolve =>{
      this.http.get('assets/data/opt.json').subscribe((res: any)=>{
        if(typeof(res) !==undefined){
          const acc = accommodation;
          console.log(acc);
          const i = res.findIndex( index => index.redirectTo ===( acc==='no'?'/reservas':'/reservas-sin'));
          console.log(i);
          res.splice(i,1);
          this.data = res;
          resolve(true);
        }
        else {
          resolve(false);
        }
          },
          err => err);
    });

}
} */
getData(){
  return this.dataTable;
}
}
