export class Emprendimiento {
	id?: number;
    name?: string;
    email?: string;
    address?: string;
    phone?: string;
    locality?: number;
    department?: number;
    coordinates?: string;
    accommodation?: string;
}
