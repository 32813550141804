/* eslint-disable @typescript-eslint/quotes */
import { Pipe, PipeTransform } from '@angular/core';
import { format, parse } from 'date-fns';
import { es } from 'date-fns/locale';

@Pipe({
  name: 'dateRange'
})
export class DateRangePipe implements PipeTransform {

  transform(value: string[], ...args: unknown[]): string {
    let startDate;
    let endDate;
    if (value[0].includes('-')) {
      if (value[0].charAt(4) === '-') {
          if (value[0].length === 10) {
              startDate = parse(value[0], 'yyyy-MM-dd', new Date());
              endDate = parse(value[1], 'yyyy-MM-dd', new Date());
          } else {
              startDate = parse(value[0], "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'", new Date());
              endDate = parse(value[1], "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'", new Date());
          }
      } else {
          startDate = parse(value[0], 'dd-MM-yyyy', new Date());
          endDate = parse(value[1], 'dd-MM-yyyy', new Date());
      }
  }
  const startMonth = this.capitalizeFirstLetter(format(startDate, 'MMMM',{ locale: es })).slice(0, 3);
  const startDay = format(startDate, 'd',{ locale: es });

  const endMonth = this.capitalizeFirstLetter(format(endDate, 'MMMM',{ locale: es })).slice(0, 3);
  const endDay = format(endDate, 'd',{ locale: es });

  if (startMonth === endMonth) {
      return ` ${startDay}-${endDay} ${startMonth}.`;
  } else {
      return `${startDay} ${startMonth}.-${endDay} ${endMonth}.`;
  }
}
  capitalizeFirstLetter(first: string) {
  return first.charAt(0).toUpperCase() + first.slice(1);
}
}
