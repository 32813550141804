import { Injectable } from '@angular/core';
import { CanLoad, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { PerfilService } from '../services/perfil.service';
import { AuthService } from '../services/auth.service';
import { filter,map,take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UsuarioGuard implements CanLoad {

  constructor(private authService: AuthService,
    private router: Router){}
   canLoad(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree{
    return   this.authService.isAuth.pipe(
      filter(val => val !== null),
      take(1),
      map(isAuth =>{
        if(isAuth){
        return true;
        }
        this.router.navigateByUrl('/login');
        return false;
      })
    );
  }
  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree{
    return   this.authService.isAuth.pipe(
      filter(val => val !== null),
      take(1),
      map(isAuth =>{
        if(isAuth){
        return true;
        }
        this.router.navigateByUrl('/login');
        return false;
      })
    );
  }

}
